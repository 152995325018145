import React, { ReactNode, ReactNodeArray } from 'react';
import { useIntl } from 'react-intl';
import { useApi } from '../../api/apiProvider';
import Button from '../common/button/Button';
import Icon from '../common/icons/Icon';

interface PulloutMenuProps {
  isOpen: boolean;
  toggleOpen: { (): void };
  children: ReactNodeArray | ReactNode;
}

const PulloutMenu = (props: PulloutMenuProps): JSX.Element => {
  const { isOpen, toggleOpen, children } = props;
  const { version } = useApi();
  const { formatMessage } = useIntl();

  return (
    <>
      <div
        aria-hidden={!isOpen}
        className={`bymelding-pullout ${isOpen ? 'bymelding-pullout-open' : 'bymelding-pullout-closed'}`}
      >
        <div
          aria-hidden
          className="bymelding-pullout-overlay"
          role="button"
          onClick={toggleOpen}
          onKeyPress={toggleOpen}
          tabIndex={-1}
        />
        <nav aria-expanded={isOpen} className="bymelding-pullout-menu bg-white h-full flex flex-col justify-start overflow-y-auto">
          <div className="flex flex-row justify-end">
            <Button
              tabIndex={isOpen ? 0 : -1}
              onClick={toggleOpen}
              name={formatMessage({ id: 'navbar.meny-toggle-close' })}
              aria-label={formatMessage({ id: 'navbar.meny-toggle-close' })}
              className="self-end mt-3"
              modifier="circle"
              osgSize="1"
              colorOption="blue-dark"
              padding="1"
            >
              <Icon icon="x" />
            </Button>
          </div>
          <div className="flex-grow">{children}</div>
          <div className="w-full self-end flex-row justify-center items-center text-white text-6">
            {version && <span>Versjon: {version}</span>}
          </div>
        </nav>
      </div>
    </>
  );
};

export default PulloutMenu;
